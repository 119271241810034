<!-- 票据明细 -->
<template>
	<div :class="themeClass">
		<div class="margin-t-20">
			<div class="ticket-detailed-title-box bg-F8F9FE flex-row-align-center padding-l-15 font-size14 font-weight700 font-color-333">票据明细</div>
			<div class="margin-t-10">
				<div v-for="(item,index) in data" :key="index" class="ticket-detailed-list-box padding-lr-15 padding-tb-10 border-bottom-F2F2F2 flex-colum-between">
					<div class="flex-row-space-between-center">
						<div class="font-size14 font-color-333">{{item.title}}</div>
						<div class="font-size14" :class="item.type=='income'?'font-color-FF0000':'font-color-1e1e1e'">￥{{item.amount|NumFormat}}</div>
					</div>
					<div class="flex-row-space-between-center font-size12 font-color-999">
						<div>源单编码：{{item.ticketNumber}}</div>
						<div>{{item.date}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {
			return {
				data: [{
						title: "票据充值收入",
						amount: "10000000",
						ticketNumber: 2020040227896,
						date: "2020-04-22",
						type: "income"
					},
					{
						title: "票据支付支出",
						amount: "-5250",
						ticketNumber: 2020040227896,
						date: "2020-05-08",
						type: "expenditure"
					},
					{
						title: "票据支付支出",
						amount: "-5250",
						ticketNumber: 2020040227896,
						date: "2020-06-24",
						type: "expenditure"
					},
					{
						title: "票据充值收入",
						amount: "10000000",
						ticketNumber: 2020040227896,
						date: "2020-06-28",
						type: "income"
					}
				]
			};
		},
		mounted() {

		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		methods: {

		}

	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.ticket-detailed-title-box {
		width: 902px;
		height: 35px;
	}

	.ticket-detailed-list-box {
		width: 887px;
		height: 45px;
	}
</style>
